import pino from 'pino';

export const log = pino({ level: process.env.LOG_LEVEL || inferDefaultLogLevel() });

function inferDefaultLogLevel() {
  if (process.env.NODE_ENV === 'test' && process.env.DEBUG_TEST == null) {
    return 'silent';
  }

  if (process.env.NODE_ENV === 'production') {
    return 'info';
  }

  return 'debug';
}
