import { BrowserWarning } from 'js/component/browser-warning';
import { ieVersion } from 'js/util/random-util';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'service-worker';
import 'stylesheet/index.less';

const RenderApp = () => {
  const minIEVersion = 11;
  const ie = ieVersion();
  if (ie > 0 && ie <= minIEVersion) {
    return <BrowserWarning />;
  }

  const App = lazy(() => import('app'));
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
  );
};

ReactDOM.render(<RenderApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
