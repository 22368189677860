export const ThemeName = 'argon';
export const ThemeTypes = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const applyThemeType = (themeType: string) => {
  removeLightTheme();
  const body = document.body;
  const themeClass = getThemeClassName(themeType);

  if (body && themeClass) {
    body.classList.add(themeClass);
  }
};

export const removeLightTheme = () => {
  const body = document.body;
  const themeClass = getThemeClassName(ThemeTypes.LIGHT);
  body.classList.remove(themeClass);
};

export const getThemeType = () => {
  return localStorage.getItem('theme:type') || ThemeTypes.DARK;
};

export const getThemeClassName = (themeType?: string) => {
  return isLightTheme(themeType) ? 'lite-color-scheme' : '';
};

export const isLightTheme = (themeType?: string) => {
  const type = themeType || getThemeType();
  return type === ThemeTypes.LIGHT;
};

export const applyNewBrightnessContrast = (brightness?: number, contrast?: number) => {
  const rootElement = document.documentElement;
  rootElement.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
  rootElement.style.webkitFilter = `brightness(${brightness}%) contrast(${contrast}%)`;
};
